<template src="./NewGroupModal.html"></template>

<script>
import idiService from "@/services/Idi";
import debounce from "lodash/debounce";
import categoryService from "@/services/Category";

export default {
  name: "new-group-modal",
  data() {
    return {
      categoryList: null,
      institutionList: null,
      groupName: null,
      university: null,
      category: null,
      groupNumber: '',
      description: '',
      coordinator: null,
      coordinatorSearchExpression: "",
      coordinatorSearch: [],
    };
  },
  props: {
    openNewGroupModal: { type: Boolean, defaul: false },
  },
  created() {
    this.getInitData();
  },
  methods: {
    customLabel (val) {
      if(val) return `${val.name} ${val.lastName}`;
    },
    async getInitData() {
      try {
        this.categoryList = await categoryService.getCategoriesWhitSubcategory();
        this.institutionList = await idiService.listInstitutions();
      } catch (error) {
        console.error("created", error);
      }
    },
    updateData() {
        (this.groupName = null),
        (this.university = null),
        (this.category = null),
        (this.groupNumber = null),
        (this.description = null),
        (this.coordinator = null),
        (this.coordinatorSearchExpression = ""),
        (this.coordinatorSearch = []);
    },
    closeModal() {
      this.$emit("closeTheNewGroupModal");
    },
    async createGroup() {
      try {
        const obj = {
          name: this.groupName,
          description: this.description,
          institutionId: parseInt(1),
          institutionName: "Institución Universitaria Pascual Bravo",
          coordinatorId: this.coordinator.id,
          categoryId: this.category,
          registrationNumber: this.groupNumber ? this.groupNumber : "",
        };
        await idiService.createGroup(obj);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se creó el grupo con éxito",
          type: "is-success",
        });
        this.updateData();
        this.$emit("closeNewGroupModalAndRefresh");
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
        this.closeModal();
      }
    },
    searchExpression: debounce(function (name) {
      if (!name.length) {
        this.coordinatorSearch = null;
        return;
      }
      setTimeout(async () => {
        try {
          this.coordinatorSearch = await idiService.listCoordinator(
            this.coordinatorSearchExpression
          );
        } catch (error) {
          console.error(error);
        }
      });
    }, 300),
  },
  computed: {
    disableButton() {
      return (
        this.groupName &&
        this.university &&
        this.category &&
        this.coordinator
      );
    },
  },
};
</script>

<style lang="scss" scoped src="./NewGroupModal.scss"></style>
